<template>
	<div class="tab-card pa-3 mb-3 border tab-custom-data" :class="tab_open ? 'border-ccc' : ''">
		<div class="d-flex" v-on:click="openTab()" :class="tab_open ? 'pb-2 border-bottom' : ''">
			<div class="reminder-width">
				<v-icon link class="mmr-2">{{ tab_open ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
				<span class="fs-500 fw-600">
					{{ remind.title ? remind.title : "" }}
				</span>

				<!-- by
				<template v-if="this.remind && this.remind.barcode">
					<v-chip
						class="mr-1 text-capitalize"
						color="cyan white--text"
						x-small
						v-if="this.remind && this.remind.barcode"
						>{{ this.remind.barcode }}
					</v-chip>
				</template> -->

				<!-- <template v-if="remind && remind.related_to">
					<v-chip class="mb-1 ml-2 mr-1 fw-500" label color="red white--text" x-small>
						{{ remind.related_to }}
					</v-chip>
				</template> -->
				<template v-if="remind && remind.callType">
					<!-- <v-chip class="mb-1 mr-1 fw-500" label color="red white--text" x-small>
						{{ remind.related_to }}
					</v-chip> -->
					<!-- <v-chip
						class="mb-1 ml-5 mr-1 fw-500"
						:color="getColor(this.remind.status)"
						x-small
						label
						outlined
						>{{ getStatus(this.remind.status) }}
					</v-chip> -->
					<!-- <v-btn
						depressed
						tile
						class="green darken-4 mr-2 my-auto"
						color="white--text"
						v-on:click="reminderPinned(remind.id)"
					>
						Pin
					</v-btn> -->
					<!-- 	<span class="ml-6" v-if="getExpiredReminder() < 0">
						<v-chip class="mb-1 ml-2 mr-1 fw-500" label color="black" x-small outlined> Expired </v-chip>
					</span> -->
				</template>
				<template>
					<v-chip class="mb-1 ml-3 mr-1 fw-500" color="cyan" x-small label outlined
						>{{ remind.start_date && formatDateTime(remind.start_date) }}
					</v-chip>
				</template>
				<template v-if="remind && remind.callType">
					<div class="d-inline-block">
						<span class="ml-2" v-if="remind.callType == 'call'">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<div v-on="on" v-bind="attrs">
										<v-icon color="green">mdi-phone</v-icon>
									</div>
								</template>
								<span>Call</span>
							</v-tooltip>
						</span>
						<span class="ml-2" v-if="remind.callType == 'email'">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<div v-on="on" v-bind="attrs">
										<v-icon color="red">mdi-email</v-icon>
									</div>
								</template>
								<span>Email</span>
							</v-tooltip>
						</span>
						<span class="ml-2" v-if="remind.callType == 'other'">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<div v-on="on" v-bind="attrs">
										<v-avatar color="cyan" size="24">
											<span class="white--text">O</span>
										</v-avatar>
									</div>
								</template>
								<span>Other</span>
							</v-tooltip>
						</span>
					</div>
				</template>
			</div>
			<v-spacer></v-spacer>
			<!-- <div v-if="getExpiredReminder() < 0" :class="open_details ? 'expired' : ' '"></div> -->
			<v-spacer></v-spacer>
			<div class="date d-flex align-items-center">
				<v-btn
					class="white--text mr-2 rounded-sm"
					depressed
					color="blue darken-4"
					tile
					v-on:click="reminderComplete(remind)"
					v-if="remind.status != 3"
				>
					Mark As Complete
				</v-btn>
				<!-- <v-btn
			    	v-if="indexReminder > 0"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					v-on:click="reminderPinned(remind.id)"
				>
				PIN
				</v-btn> -->
				<v-btn
					v-if="remind.status != 3"
					width="32"
					min-width="32"
					class="white--text mr-2 rounded-sm"
					depressed
					outlined
					color="blue darken-4"
					tile
					v-on:click="reminderEdit(remind.id, 'Update')"
					:disabled="isDisabled"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn
					depressed
					width="32"
					min-width="32"
					outlined
					class="white--text mr-2 rounded-sm"
					color="red darken-4"
					tile
					v-on:click="reminderDel(remind)"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>

				<!-- <v-btn
					v-if="indexReminder > 0"
					depressed
					tile
					class="blue darken-4 mr-2 my-auto"
					color="white--text"
					v-on:click="reminderPinned(remind.id)"
				>
					Pin
				</v-btn> -->
				<!-- <v-menu offset-y left content-class="custom-menu" max-width="130px">
					<template v-slot:activator="{ on, attrs }">
						<div class="primary--text" v-bind="attrs" v-on="on">
							<span class="fs-500 fw-600">Actions </span>
							<v-icon link class="mmr-2 primary--text">mdi-menu-down</v-icon>
						</div>
					</template>
					<v-list tile>
						<v-list-item-group>
							<v-list-item v-if="indexReminder > 0">
								<v-list-item-title v-on:click="reminderPinned(remind.id)">Pin</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="remind.status != 3">
								<v-list-item-title v-on:click="reminderEdit(remind.id, 'Update')">Edit</v-list-item-title>
							</v-list-item>
							<v-list-item>
								<v-list-item-title v-on:click="reminderDel(remind)">Delete</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="remind.status != 3">
								<v-list-item-title v-on:click="reminderComplete(remind)">Mark As Complete</v-list-item-title>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-menu> -->
			</div>
		</div>
		<div v-if="tab_open">
			<div>
				<div class="row">
					<div class="col-md-2 fw-500 fw-400 py-2">Type</div>
					<div class="col-md-8">
						<div v-if="remind && remind.type">{{ getReminderType(remind.type) }}</div>
					</div>
					<!-- <div class="col-md-2">
						<div class="float-right">
							<v-switch
								class="mt-0"
								inset
								color="indigo"
								hide-details
								v-model="remind.activated"
								v-on:change="updateReminder(remind)"
							>
							</v-switch>
						</div>
					</div> -->
				</div>
				<div class="row" v-if="false">
					<div class="col-md-2 fw-500 fw-400 py-2">Attendees</div>
					<div class="col-md-10">
						<template v-if="remind && remind.attendies">
							<span v-for="(staff, index) in remind.attendies" :key="index">
								<v-chip left small v-if="staff && staff.display_name">
									<v-avatar left small>
										<img v-if="staff.image" :src="staff.image" />
										<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
									</v-avatar>
									<span class="fw-500"> {{ staff.display_name }}</span>
								</v-chip>
							</span>
						</template>
					</div>
				</div>
				<div class="row">
					<div class="col-md-2 fw-400 py-2">
						<template>Reminder Date </template>
					</div>
					<div class="col-md-10 py-2 fw-500">
						<div v-if="remind && remind.start_date">{{ formatDateTime(remind.start_date) }}</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-2 fw-500 fw-400 py-2">Description</div>
					<div class="col-md-10">
						<div v-if="remind && remind.description" v-html="remind.description"></div>
						<em v-else class="text-muted"> no description</em>
					</div>
				</div>
			</div>
			<v-spacer></v-spacer>

			<!-- <div class="row" v-if="remind && remind.related_to">
				<div class="col-md-2 fw-500 fw-400 py-2">Related To</div>
				<div class="col-md-10">
					<div v-if="remind && remind.related_to"><v-chip
										class="ml-2 text-capitalize"
										color="red white--text"
										x-small
										label
										v-if="this.remind && this.remind.related_to"
										>{{ this.remind.related_to.replace("-", " ") }}</v-chip
									></div>
				</div>
			</div>	 -->
			<!-- <div class="d-flex border-top py-3">
				<div>
					<span class="fs-500 fw-400">Attendees</span>
					<div class="primary--text">
						<span class="fs-500 fw-600" v-if="remind.attendies"
							>{{ remind.attendies.length > 0 ? remind.attendies.length : 0 }} Attendees</span
						>
					</div>
				</div>
			</div> -->
		</div>
		<DeleteTemplate
			type="Reminder"
			:deleteUrl="delURL"
			:deleteText="delTEXT"
			:deleteDialog="confirm_dialog"
			v-on:close="confirm_dialog = false"
			v-on:success="
				confirm_dialog = false;
				$emit('deleteSuccess', remind);
				$emit('reminderSuccessNew', remind);
				$emit('reminderSuccess', remind);
			"
		></DeleteTemplate>

		<ReminderCompleteTemplate
			type="Reminder"
			:completeUrl="completeURL"
			:completeText="completeTEXT"
			:completeDialog="complete_dialog"
			v-on:close="complete_dialog = false"
			v-on:success="
				complete_dialog = false;
				$emit('reminderSuccessNew', remind);
				$emit('reminderSuccess', remind);
			"
			:dataObject="remind"
		></ReminderCompleteTemplate>
	</div>
</template>
<script>
import { PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ReminderCompleteTemplate from "@/view/components/ReminderCompleteTemplate";
import MomentJS from "moment-timezone";

export default {
	name: "ReminderTab",
	components: {
		DeleteTemplate,
		ReminderCompleteTemplate,
	},
	props: {
		isDisabled: {
			type: Boolean,
			default: false,
		},
		open_details: {
			type: Boolean,
			default: false,
		},
		remind: {
			type: Object,
		},
		indexReminder: {
			type: Number,
		},
	},
	data() {
		return {
			tab_open: false,
			delURL: null,
			delTEXT: null,
			confirm_dialog: false,
			complete_dialog: false,
			completeURL: null,
			completeTEXT: null,
			completeSuccess: null,
		};
	},
	watch: {
		open_details(param) {
			this.tab_open = param;
		},
		/* open_details(open_details){
			if(open_details && this.remind.activated == 0){
					this.remind.activated  = true;
				}else{
					this.remind.activated  = false;
				}	

		},
		indexReminder(indexReminder){
			if(indexReminder && this.remind.activated == 0){
					this.remind.activated  = true;
				}else{
					this.remind.activated  = false;
				}	

		} */
	},
	methods: {
		/* reminder_type_items: [
				{ value: 1, text: "One Time Reminder" },
				{ value: 2, text: "Daily Reminder" },
				{ value: 3, text: "Weekly Reminder" },
				{ value: 4, text: "Monthly Reminder" },
				{ value: 5, text: "Quarterly Reminder" },
				{ value: 6, text: "Half Yearly Reminder" },
			], */
		openTab() {
			this.tab_open = !this.tab_open;
		},
		getStatus(type) {
			if (type == 1) {
				return "Overdue";
			} else if (type == 2) {
				return "Upcoming";
			} else if (type == 3) {
				return "Complete";
			}
		},
		getColor(type) {
			if (type == 1) {
				return "red white--text";
			} else if (type == 2) {
				return "blue white--text";
			} else if (type == 3) {
				return "green white--text";
			}
		},
		getReminderType(data) {
			if (data == 1) {
				return "One Time Reminder";
			}
			if (data == 2) {
				return "Daily Reminder";
			}
			if (data == 3) {
				return "Weekly Reminder";
			}
			if (data == 4) {
				return "Monthly Reminder";
			}
			if (data == 5) {
				return "Quarterly Reminder";
			}
			if (data == 6) {
				return "Half Yearly Reminder";
			}
		},
		getExpiredReminder() {
			let a = MomentJS();
			let b = MomentJS(this.remind.start_date);
			return b.diff(a, "days");
		},
		reminderDel(row) {
			this.delTEXT = `(${row.barcode}) ` + row.title;
			this.delURL = `reminder/${row.id}`;
			this.confirm_dialog = true;
			/* this.$emit("reminderSuccess", row);
			this.$emit("reminderSuccessNew", row);
			 this.$emit("deleteSuccess", row); */
		},
		reminderEdit(id, title) {
			this.$emit("editReminder", id);
			this.$emit("editTitle", title);
		},
		updateReminder(remind) {
			if (remind.activated == true) {
				remind.activated = 1;
			} else {
				remind.activated = 0;
			}
			this.$store
				.dispatch(PATCH, {
					url: `status-reminder/${remind.id}`,
					data: {
						activated: remind.activated,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder update successfully." },
					]);
					this.$emit("reminderSuccess", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},

		reminderComplete(remind) {
			this.completeTEXT = `(${remind.barcode}) ` + remind?.title;
			this.completeURL = `reminder-complete/${remind.id}`;
			this.complete_dialog = true;

			//this.$emit("reminderSuccessNew", this.completeSuccess);

			//console.log(this.complete_dialog);

			// this.$store
			// 	.dispatch(PATCH, {
			// 		url: `reminder-complete/${remind.id}`,
			// 		data: {
			// 			status: 3,
			// 		},
			// 	})
			// 	.then(() => {
			// 		this.$store.commit(SET_MESSAGE, [
			// 			{ model: true, message: "Success ! Reminder complete successfully." },
			// 		]);
			// 		remind.status_marked = 'complete';
			// 		this.$emit("reminderSuccessNew", remind);
			// 	})
			// 	.catch((error) => {
			// 		console.log({ error });
			// 	});
		},
		reminderPinned(id) {
			this.$store
				.dispatch(PATCH, { url: `pin-reminder/${id}` })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder Pinned successfully." },
					]);
					this.$emit("reminderSuccess", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	beforeMount() {
		this.lead_id = this.$route.params.id;
	},
	mounted() {},
};
</script>
