<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">Add Company to this Contact</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						class="blue darken-4"
						:loading="pageLoading"
						:disabled="pageLoading || !formValid"
						v-on:click="addCompany()"
						>Save</v-btn
					>
				</v-flex>
				<v-form
					ref="form"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="addCompany"
				>
					<div class="drawer-content pt-5 px-5">
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Company domain name</label>
							</div>
							<v-text-field
								depressed
								hide-details
								outlined
								v-model="company.company_domain_name"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(company.company_domain_name, 'Domain Name')]"
								:class="{
									required: !company.company_domain_name,
								}"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Company name</label>
							</div>
							<v-text-field
								depressed
								hide-details
								outlined
								v-model="company.company_name"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(company.company_name, 'Company name')]"
								:class="{
									required: !company.company_name,
								}"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Company owner</label>
							</div>
							<v-autocomplete
								:items="ownerList"
								item-text="text"
								item-value="value"
								depressed
								hide-details
								outlined
								v-model="company.company_owner"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(company.company_owner, 'Company owner')]"
								:class="{
									required: !company.company_owner,
								}"
							></v-autocomplete>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Industry</label>
							</div>
							<v-autocomplete
								:items="industryList"
								item-text="text"
								item-value="value"
								depressed
								hide-details
								outlined
								v-model="company.industry"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(company.industry, 'Industry')]"
								:class="{
									required: !company.industry,
								}"
							></v-autocomplete>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Address</label>
							</div>
							<v-text-field
								depressed
								hide-details
								outlined
								v-model="company.address"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(company.address, 'Address')]"
								:class="{
									required: !company.address,
								}"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">City</label>
							</div>
							<v-text-field
								depressed
								hide-details
								outlined
								v-model="company.city"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(company.city, 'City')]"
								:class="{
									required: !company.city,
								}"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Phone</label>
							</div>
							<PhoneTemplate
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="phone_number"
								v-model="company.phone"
							></PhoneTemplate>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">State</label>
							</div>
							<v-text-field
								depressed
								hide-details
								outlined
								v-model="company.state"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(company.state, 'State')]"
								:class="{
									required: !company.state,
								}"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Postal Code</label>
							</div>
							<v-text-field
								depressed
								hide-details
								outlined
								v-model.trim="company.postal_code"
								:rules="[
									vrules.required(company.postal_code, 'Postal Code'),
									vrules.maxLength(company.postal_code, 'Postal Code', 10),
								]"
								v-mask="'######'"
								type="Number"
								:class="{
									required: !company.postal_code,
								}"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">No of employees</label>
							</div>
							<v-text-field
								type="number"
								depressed
								hide-details
								outlined
								v-model="company.no_of_employees"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Annual revenue</label>
							</div>
							<v-text-field
								type="number"
								depressed
								hide-details
								outlined
								v-model="company.annual_revenue"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Description</label>
							</div>
							<v-textarea depressed hide-details outlined v-model="company.description"></v-textarea>
						</div>
					</div>
				</v-form>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { GET, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import PhoneTemplate from "@/view/components/Phone";

export default {
	mixins: [MainMixin],
	name: "AddCompany",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formValid: true,
			lead_id: null,
			pageLoading: false,
			company: {
				company_domain_name: null,
				company_name: null,
				company_owner: null,
				industry: null,
				address: null,
				city: null,
				phone: null,
				state: null,
				postal_code: null,
				no_of_employees: null,
				annual_revenue: null,
				description: null,
			},
			ownerList: [],
			industryList: [],
		};
	},
	components: {
		PhoneTemplate,
	},
	methods: {
		getCompanySetting() {
			this.$store
				.dispatch(GET, { url: `lead/company-setting` })
				.then((data) => {
					this.industryList = data.industry;
					this.ownerList = data.company_owner;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		addCompany() {
			const formErrors = this.validateForm(this.$refs["form"]);

			this.$refs["form"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["form"].validate()) {
				return false;
			}
			this.pageLoading = true;
			this.$store
				.dispatch(POST, { url: `lead/${this.lead_id}/company`, data: this.company })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Company added successfully." },
					]);
					this.$refs.form.reset();
					this.company.description = "";
					this.$emit("updateCompanyList", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
	mounted() {
		this.getCompanySetting();
	},
	beforeMount() {
		this.lead_id = this.$route.params.id;
	},
};
</script>
<style scoped></style>
