<template>
	<Dialog :dialog="completeDialog" :dialog-width="600" @close="$emit('close')">
		<template v-slot:title> Complete {{ type }}</template>
		<template v-slot:body>
			<v-row class="delete-dialog">
				<v-col md="2" class="py-0 text-right my-auto">
					<span class="svg-icon svg-icon-lg delete-confirm-icon">
						<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
					</span>
				</v-col>
				<v-col md="10" class="py-0 my-auto">
					<p class="btx-p m-0">
						Mark as Complete <span class="text-capitalize">{{ type }}</span>
						<span class="red--text lighten-1 font-level-1-bold"> {{ completeText }}</span>
					</p>
				</v-col>
				<v-col md="10" class="py-0 my-auto">
					<p class="btx-p m-0">
						<template>
							<v-col md="12" class="py-0">
								<RadioInput
									row
									hide-details
									v-model="thisOrAll"
									id="reminder-this"
									:items="reminderItems"
								></RadioInput>
							</v-col>
						</template>
					</p>
				</v-col>
				<!-- <v-col v-if="deleteNote" md="12" class="pb-0 my-auto">
					<em>Note: {{ deleteNote }}</em>
				</v-col> -->
			</v-row>
		</template>
		<template v-slot:action>
			<slot name="extra-btn"></slot>
			<v-btn
				class="white--text"
				:loading="deleteButton"
				:disabled="deleteButton"
				depressed
				color="red lighten-1"
				tile
				v-on:click="deleteRow()"
			>
				Yes! Complete
			</v-btn>
			<v-btn depressed tile :disabled="deleteButton" v-on:click="$emit('close', true)">
				No, Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
//import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import RadioInput from "@/view/components/RadioInput";
import { PATCH } from "@/core/services/store/request.module";

export default {
	name: "delete-template",
	title: "Delete Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		completeUrl: {
			type: String,
			default: null,
		},
		completeText: {
			type: String,
			default: null,
		},
		completeNote: {
			type: String,
			default: null,
		},
		completeDialog: {
			type: Boolean,
			default: false,
		},
		dataObject: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			deleteButton: false,
			reminderItems: [
				{
					label: "This Reminder",
					value: "this",
				},
				{
					label: "All This Type Reminder",
					value: "all",
				},
			],
			thisOrAll: "this",
		};
	},
	components: {
		Dialog,
		RadioInput,
	},
	methods: {
		deleteRow() {
			this.deleteButton = true;
			this.$store
				.dispatch(PATCH, {
					url: this.completeUrl,
					data: {
						status: 3,
						this_or_all: this.thisOrAll,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder completed successfully." },
					]);
					this.$emit("success", true);
					this.$emit("close", true);
					//remind.status_marked = 'complete';
					//this.completeDialog = false;
					this.deleteButton = false;
					this.$emit("completeSuccess", this.dataObject);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
};
</script>
