<template>
	<Dialog :dialog="deleteDialog" :dialog-width="600" @close="$emit('close', true)">
		<template #title> Delete {{ type }}</template>
		<template #body>
			<v-row class="delete-dialog">
				<v-col md="2" class="py-0 text-right my-auto">
					<span class="svg-icon svg-icon-lg delete-confirm-icon">
						<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
					</span>
					<!--begin::Svg Icon-->
				</v-col>
				<v-col md="10" class="py-0 my-auto">
					<p class="btx-p m-0">
						<template v-if="type != 'Seo-Project-Analysis'">
							Deleting <span class="text-capitalize">{{ type }}</span>
							<span class="red--text lighten-1 font-level-1-bold"> {{ deleteText }}</span> is irreversible
							and will also delete all associated records.<br />
						</template>
						Are you sure about deleting it?
					</p>
				</v-col>
				<!-- <v-col v-if="deleteNote" md="12" class="pb-0 my-auto">
					<em>Note: {{ deleteNote }}</em>
				</v-col> -->
			</v-row>
		</template>
		<template #action>
			<slot name="extra-btn"></slot>
			<v-btn
				class="white--text"
				:loading="deleteButton"
				:disabled="deleteButton"
				depressed
				color="red lighten-1"
				tile
				@click="deleteRow"
			>
				Yes! Delete
			</v-btn>
			<v-btn depressed tile :disabled="deleteButton" @click="$emit('close', true)"> No, Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "delete-template",
	title: "Delete Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		deleteUrl: {
			type: String,
			default: null,
		},
		deleteText: {
			type: String,
			default: null,
		},
		deleteNote: {
			type: String,
			default: null,
		},
		deleteDialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			deleteButton: false,
		};
	},
	components: {
		Dialog,
	},
	methods: {
		deleteRow() {
			if (!this.deleteUrl) {
				this.$emit("success", true);
			} else {
				this.deleteButton = true;
				//console.log("deleteUrl => ",this.deleteUrl);
				ApiService.delete(this.deleteUrl)
					.then((data) => {
						if (data.status) {
							this.$store.commit(SET_MESSAGE, [
								{ model: true, message: `Success ! ${this.type} has been deleted.` },
							]);
							this.$emit("success", true);
							this.$emit("close", true);
						}
					})

					.catch((error) => {
						this.logError(error);
					})
					.finally(() => {
						this.deleteButton = false;
					});
			}
		},
	},
};
</script>
