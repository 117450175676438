<template>
	<div>
		<v-icon link class="input-addon primary--text" :class="customClass" v-on:click="drawer = !drawer">
			mdi-cog
		</v-icon>
		<v-navigation-drawer
			class="productUnitDrawer"
			v-model="drawer"
			app
			stateless
			right
			temporary
			width="400px"
		>
			<v-card class="" tile elevation="0">
				<v-card-title class="flex py-3 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">{{ title }}</div>
					<v-spacer></v-spacer>
					<v-btn
						tile
						depresed
						color="white--text"
						class="blue darken-4 mr-2"
						:disabled="!formValid"
						v-on:click="addSetting()"
					>
						Save
					</v-btn>
					<v-btn tile depresed class="darken-4" v-on:click="drawer = false"> Close </v-btn>
				</v-card-title>
				<v-card-text class="d-flex px-4">
					<v-form ref="industryService" v-model.trim="formValid">
						<v-row>
							<v-col
								md="12"
								cols="12"
								class="my-auto py-0 pr-12 position-relative"
								v-for="(data, index) in create_setting"
								:key="index"
							>
								<TextInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									:placeholder="title"
									v-model="create_setting[index].text"
									:rules="[vrules.required(create_setting[index].text, 'Industry')]"
									:class="{ required: !create_setting[index].text }"
								>
								</TextInput>

								<template v-if="data.id">
									<v-icon
										class="red--text cursor-pointer"
										style="position: absolute; top: 14px; right: 14px"
										v-on:click="deleteSetting(data.id)"
										>mdi-delete
									</v-icon>
								</template>
								<template v-else>
									<v-icon
										class="red--text cursor-pointer"
										style="position: absolute; top: 14px; right: 14px"
										v-on:click="deletefield(index)"
										>mdi-delete
									</v-icon>
								</template>
							</v-col>
							<v-col>
								<v-btn tile depressed color="blue darken-4" class="white--text" v-on:click="addField">
									<v-icon>mdi-plus</v-icon>
									Add
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-navigation-drawer>
	</div>
</template>

<script>
import TextInput from "@/view/components/TextInput";
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "ManageAutocomplete",
	props: {
		title: {
			type: String,
			default: null,
		},
		typeId: {
			default: 0,
		},
		customClass: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			drawer: false,
			pageLoading: false,
			formValid: true,
			create_setting: [
				{
					text: null,
				},
			],
		};
	},
	methods: {
		addField() {
			this.create_setting.push({
				text: null,
			});
		},
		deletefield(index) {
			this.create_setting.splice(index, 1);
		},
		addSetting() {
			const unique_array_payload = this.create_setting.reduce((uniq, curr_val) => {
				if (!uniq.some((item) => item.text == curr_val.text)) {
					uniq.push(curr_val);
				}
				return uniq;
			}, []);

			if (unique_array_payload.length) {
				this.$store
					.dispatch(POST, {
						url: "lead-setting-update",
						data: {
							type: this.typeId,
							status: unique_array_payload,
						},
					})
					.then(() => {
						this.drawer = false;
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Setting updated successfully.",
							},
						]);
						this.$emit("update", true);
						this.getSetting();
					})
					.catch((error) => {
						console.log({
							error,
						});
					})
					.finally(() => {
						this.pageLoading = false;
					});
			}
		},
		getSetting() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `single-setting/${this.typeId}`,
				})
				.then((data) => {
					if (data && data.length) {
						0;
						this.create_setting = data;
					} else {
						this.create_setting = [
							{
								text: null,
							},
						];
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		deleteSetting(id) {
			this.$store
				.dispatch(DELETE, {
					url: `lead-setting/${id}`,
				})
				.then(() => {
					this.getSetting();
					this.$refs["industryService"].validate();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
	},
	components: {
		TextInput,
	},
	mounted() {
		this.getSetting();
	},
};
</script>
