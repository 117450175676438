<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">Add Deal to this Contact</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						class="blue darken-4"
						v-on:click="addCompanyDeal()"
						:loading="pageLoading"
						:disabled="pageLoading || !formValid"
						>Save</v-btn
					>
				</v-flex>
				<v-form
					ref="form"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="addCompany"
				>
					<div class="drawer-content pt-5 px-5">
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Deal Name</label>
							</div>
							<v-text-field
								depressed
								hide-details
								outlined
								v-model="deal.deal_name"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(deal.deal_name, 'Deal Name')]"
								:class="{
									required: !deal.deal_name,
								}"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Pipeline</label>
							</div>
							<v-autocomplete
								:items="pipelineList"
								item-text="text"
								item-value="value"
								depressed
								hide-details
								outlined
								v-model="deal.pipeline"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(deal.pipeline, 'Pipeline')]"
								:class="{
									required: !deal.pipeline,
								}"
							></v-autocomplete>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Deal stage</label>
							</div>
							<v-autocomplete
								:items="dealStageList"
								item-text="text"
								item-value="value"
								depressed
								hide-details
								outlined
								v-model="deal.deal_stage"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(deal.deal_stage, 'Deal stage')]"
								:class="{
									required: !deal.deal_stage,
								}"
							></v-autocomplete>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Amount</label>
							</div>
							<v-text-field
								type="number"
								depressed
								hide-details
								outlined
								:rules="[vrules.required(deal.amount, 'Amount')]"
								:class="{
									required: !deal.amount,
								}"
								v-model="deal.amount"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Close Date</label>
							</div>
							<DatePicker
								hide-details
								clearable
								:disabled="pageLoading"
								:loading="pageLoading"
								id="Close-date"
								placeholder="Close Date"
								v-model="deal.close_date"
								:rules="[vrules.required(deal.close_date, 'Close Date')]"
								:class="{
									required: !deal.close_date,
								}"
							></DatePicker>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Deal owner</label>
							</div>
							<v-autocomplete
								:items="ownerList"
								item-text="text"
								item-value="value"
								depressed
								hide-details
								outlined
								v-model="deal.deal_owner"
								:rules="[vrules.required(deal.deal_owner, 'Deal owner')]"
								:class="{
									required: !deal.deal_owner,
								}"
							></v-autocomplete>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Deal Type</label>
							</div>
							<v-autocomplete
								:items="dealTypeList"
								item-text="text"
								item-value="value"
								depressed
								hide-details
								outlined
								v-model="deal.deal_type"
								:rules="[vrules.required(deal.deal_type, 'Deal Type')]"
								:class="{
									required: !deal.deal_type,
								}"
							></v-autocomplete>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Priority</label>
							</div>
							<v-autocomplete
								:items="priorityList"
								item-text="text"
								item-value="value"
								depressed
								hide-details
								outlined
								v-model="deal.priority"
								:rules="[vrules.required(deal.priority, 'Priority')]"
								:class="{
									required: !deal.priority,
								}"
							></v-autocomplete>
						</div>
						<v-divider></v-divider>
						<div class="pb-3">
							<span class="font-level-3-bold primary--text">Associate deal width</span>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Company</label>
							</div>
							<v-autocomplete
								:items="companyList"
								item-text="company_name"
								item-value="id"
								depressed
								hide-details
								outlined
								v-model="deal.deal_with_company"
								:rules="[vrules.required(deal.deal_with_company, 'Company')]"
								:class="{
									required: !deal.deal_with_company,
								}"
							></v-autocomplete>
							<v-checkbox
								class="size-18 mt-2 pa-0"
								hide-details
								v-model="deal.company_timeline"
								label="Add timeline activity from BThrust to this deal"
							>
							</v-checkbox>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Contact</label>
							</div>
							<v-autocomplete
								:items="contactList"
								item-text="text"
								item-value="value"
								depressed
								hide-details
								outlined
								v-model="deal.deal_with_contact"
								:rules="[vrules.required(deal.deal_with_contact, 'Contact')]"
								:class="{
									required: !deal.deal_with_contact,
								}"
							></v-autocomplete>
							<v-checkbox
								class="size-18 mt-2 pa-0"
								hide-details
								v-model="deal.deal_timeline"
								label="Add timeline activity from BThrust to this deal"
							>
							</v-checkbox>
						</div>
					</div>
				</v-form>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { GET, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import DatePicker from "@/view/components/DatePicker";

export default {
	mixins: [MainMixin],
	name: "CreateDeal",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formValid: true,
			pipelineList: [],
			dealStageList: [],
			pageLoading: false,
			ownerList: [],
			dealTypeList: [],
			date_menu: false,
			priorityList: [],
			companyList: [],
			contactList: [],
			deal: {
				deal_name: null,
				pipeline: null,
				deal_stage: null,
				amount: [],
				close_date: null,
				deal_owner: null,
				deal_type: null,
				priority: null,
				deal_with_company: null,
				company_timeline: null,
				deal_with_contact: null,
				deal_timeline: null,
			},
		};
	},
	components: {
		DatePicker,
	},
	methods: {
		getDealSetting() {
			this.$store
				.dispatch(GET, { url: `lead/deal-contact-setting` })
				.then((data) => {
					this.pipelineList = data.pipeline;
					this.dealStageList = data.deal_stage;
					this.ownerList = data.deal_owner;
					this.dealTypeList = data.deal_type;
					this.priorityList = data.priority;
					this.companyList = data.company;
					this.contactList = data.contact;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		addCompanyDeal() {
			const formErrors = this.validateForm(this.$refs["form"]);
			this.$refs["form"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["form"].validate()) {
				return false;
			}

			this.pageLoading = true;
			this.$store
				.dispatch(POST, { url: `lead/${this.lead_id}/company-deal`, data: this.deal })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Deal added successfully." },
					]);
					this.$refs.form.reset();
					this.$emit("updateDealList", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.getDealSetting();
	},
	computed: {
		...mapGetters(["errors"]),
	},
	beforeMount() {
		this.lead_id = this.$route.params.id;
	},
};
</script>
<style scoped></style>
