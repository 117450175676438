<template>
	<div class="tab-card pa-3 mb-3 border tab-custom-data">
		<div class="d-flex mb-2 fw-500">
			<div class="">
				<span class="fw-600">{{ title }}</span>
				Activity
			</div>

			<v-spacer></v-spacer>
			<div class="date d-flex align-items-center">
				<v-icon class="mdi-16px mr-1 primary--text">mdi-calendar-clock-outline</v-icon>
				<span>Date : {{ formatDateTime(log.added_at) }}</span>
			</div>
		</div>
		<div class="d-flex mb-2 fw-500">
			<span class="fw-600 primary--text">{{ log.display_name }} </span>
			{{ log.description }}
			<span class="fw-600 cursor-pointer ml-1"
				>{{ log.module }} <v-icon class="mdi-16px">mdi-open-in-new</v-icon></span
			>
			to New.
			<!-- <span class="cursor-pointer primary--text fw-600"
				>View details <v-icon class="mdi-16px">mdi-open-in-new</v-icon></span
			> -->
			<v-spacer></v-spacer>
			<div class="date d-flex align-items-center" v-if="log.ip">
				<span>Ip Address: {{ log.ip }}</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Activity",
	props: {
		open_details: {
			type: Boolean,
			default: false,
		},
		log: {
			type: Object,
		},
		title: {
			type: String,
		},
	},
	data() {
		return {
			assign_task: "Test Task",
			isEditing: false,
		};
	},
};
</script>
